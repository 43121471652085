'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Extend default messages for the jQuery validation plugin.
     * Locale: EN (English)
     */
    $.extend($.validator.messages, {
        'invalid': 'Please enter valid data',
        'legalDate': 'You have to be at least 18 years old to order at the Jack Wolfskin Shop.',
        'invalidZip': 'Please enter a valid Zip Code',
        'invalidPassword': 'The password must contain at least one special character, a capital letter and a number and needs to have at least 8 characters.',
        'passwordsDontMatch': 'Does not match password.',
        'streetMissingHouseNumber': 'You haven’t included the house number in your street. Is this correct?'
    });
    return $;
}));
