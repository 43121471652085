'use strict';

(function ($) {
    var $formElements = $('.jwsdw-form-validate'),
        countryLists = $('.jwsdw-input-country, .jwsdw-country'),
        template = document.querySelector('#warningMessage'),
        templateContent = template.content,
        classesToCheck = ['jws-formError', 'jwsdw-softFormError'];

    countryLists.each(function() {
        var countryList = $(this),
            countryListItems = countryList.children('option').get();

        countryListItems.sort(function(a, b) {
            if (!$(a).attr('value')) {
                return -1;
            }
            if (!$(b).attr('value')) {
                return 1;
            }
            return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
        });
        $.each(countryListItems, function(idx, itm) {
            countryList.append(itm);
        });
    });

    $.validator.setDefaults({
        'errorClass': 'jws-formErrorMessage jws-copy1',
        'validClass': 'jws-formLabelValid',
        'rules': {
            'email': {
                'invalidvalue': true,
                'customemail': true
            }
        },
        'ignore': ':hidden, .jwsdw-validate-ignore, .jwsdw-validate-disable',
        'errorPlacement': function(error, element) {
            let $element = $(element),
                $error = $(error),
                templateContentClone = templateContent.cloneNode(true),
                templateElement = templateContentClone.querySelector(".jws-notificationWrapper"),
                siblingError = $element.siblings('.jws-notificationWrapper'),
                errorDisplayed = siblingError.length ? true : false,
                message,
                displayedNotificationMessage,
                displayedNotificationText;

            const hasAllClasses = classesToCheck.every(className => templateElement.classList.contains(className));

            if (hasAllClasses) {
                classesToCheck.forEach(className => {
                    templateContent.classList.add(className);
                });
            }

            if ($element.data('error') === 'warning' && $element.hasClass('jwsdw-softFormError')) {
                message = templateContentClone.querySelector('.jws-notificationMessage');
                message.textContent = $error.text();
                message.id = $element.prop('name') + '-warningMessage';

                if (!errorDisplayed) {
                    $element.parent().append(templateContentClone);
                    $element.attr('aria-describedby', $element.prop('name') + '-warningMessage');
                } else {
                    displayedNotificationMessage = $element.parent().find('.jws-notificationMessage');
                    displayedNotificationText = $element.val().length === $element.prop('maxLength') ? $element.data('error-message-length') : $element.data('error-message');
                    displayedNotificationMessage.text(displayedNotificationText);
                }

                return;
            }

            if ($element.attr('type') === 'file') {
                $element.parent('.jws-fileInputWrapper').addClass('jws-fileInputWrapperError');
                error.appendTo($element.parent('.jws-fileInputWrapper').next('.jws-formErrorMessage'));
                $element.parent('.jws-fileInputWrapper').next('.jws-formErrorMessage').removeClass('jws-hidden');
            } else if ($element.attr('type') === 'radio') {
                error.appendTo($element.parent().parent().parent());
            } else {
                if ($element.attr('id') &&
                    ($element.attr('id').indexOf('selectedBirthday') > 0 ||
                    $element.attr('id').indexOf('dateselect') > 0 ||
                    $element.attr('id').indexOf('dateOfBirth') > 0)) {
                    error.appendTo($element.closest('form').find('.jwsdw-error-container'));
                } else if ($element.parent().hasClass('jws-selectWrapper') || $element.parent().hasClass('jws-roundWrapper')) {
                    error.appendTo($element.parent().parent());
                } else {
                    error.appendTo($element.parent());
                }
            }
        },
        'highlight': function(element, errorClass, validClass) {
            let $element = $(element),
                $form = $element.closest('form'),
                templateContentClone = templateContent.cloneNode(true),
                siblingError = $element.siblings('.jws-notificationWrapper'),
                errorDisplayed = siblingError.length ? true : false,
                $radioElements,
                message,
                displayedNotificationMessage;

            if ($element.attr('type') === 'file') {
                $element.parent('.jws-fileInputWrapper').addClass('jws-fileInputWrapperError');
                $element.parent('.jws-fileInputWrapper').removeClass(validClass);

                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputName, .jws-fileInputName-name').addClass('jws-colorRedStatus');
                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputName, .jws-fileInputName-name').removeClass('jws-colorGreenStatus');

                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputButton').addClass('jws-fileInputButtonError');
                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputButton').removeClass('jws-fileInputButtonSuccess');
            } else if ($element.attr('type') !== 'radio') {
                if (!$element.hasClass('jwsdw-softFormError')) {
                    $element.siblings('.jws-notificationWrapper').remove();
                }
                if ($element.data('error') === 'warning' && $element.hasClass('jwsdw-softFormError')) {
                    message = templateContentClone.querySelector('.jws-notificationMessage');
                    if ($element.val().length === $element.prop('maxLength') && $element.data('error-message-length')) {
                        message.textContent = $element.data('error-message-length');
                    } else {
                        message.textContent = $element.data('error-message');
                    }
                    message.id = $element.prop('name') + '-warningMessage';

                    if (!errorDisplayed) {
                        $element.parent().append(templateContentClone);
                        $element.attr('aria-describedby', $element.prop('name') + '-warningMessage');
                    } else {
                        displayedNotificationMessage = $element.parent().find('.jws-notificationMessage');
                        displayedNotificationMessage.text(message.textContent);
                    }

                    return;
                }

                if (element.id) {
                    $element.addClass('jws-formError');
                    $form.find('label[for=' + element.id + ']').addClass('jws-formError');
                    $form.find('label[for=' + element.id + ']').removeClass(validClass);
                }
            } else {
                $radioElements = $form.find('[name=' + $element.attr('name') + ']');
                if (element.id) {
                    $radioElements.each(function () {
                        $form.find('label[for=' + this.id + ']').addClass('jws-formError');
                    });
                }
            }
        },
        'unhighlight': function(element, errorClass, validClass) {
            let $element = $(element),
                $form = $element.closest('form'),
                templateContentClone = templateContent.cloneNode(true),
                siblingError = $element.siblings('.jws-notificationWrapper'),
                errorDisplayed = siblingError.length ? true : false,
                $radioElements,
                message,
                displayedNotificationMessage;

            if ($element.attr('type') === 'file') {
                $element.parent('.jws-fileInputWrapper').removeClass('jws-fileInputWrapperError');
                $element.parent('.jws-fileInputWrapper').next('.jws-formErrorMessage').addClass('jws-hidden');
                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputName, .jws-fileInputName-name').removeClass('jws-colorRedStatus');
                $element.parent('.jws-fileInputWrapper').find('.jws-fileInputButton').removeClass('jws-fileInputButtonError');
            } else if ($element.attr('type') !== 'radio') {
                if (!$element.hasClass('jwsdw-softFormError')) {
                    $element.siblings('.jws-notificationWrapper').remove();
                }
                if ($element.data('error') === 'warning' && $element.hasClass('jwsdw-softFormError')) {
                    message = templateContentClone.querySelector('.jws-notificationMessage');
                    if ($element.val().length === $element.prop('maxLength') && $element.data('error-message-length')) {
                        message.textContent = $element.data('error-message-length');
                    } else {
                        message.textContent = $element.data('error-message');
                    }
                    message.id = $element.prop('name') + '-warningMessage';

                    if (!errorDisplayed) {
                        $element.parent().append(templateContentClone);
                        $element.attr('aria-describedby', $element.prop('name') + '-warningMessage');
                    } else {
                        displayedNotificationMessage = $element.parent().find('.jws-notificationMessage');
                        displayedNotificationMessage.text(message.textContent);
                    }

                    return;
                }
                if (element.id) {
                    $element.removeClass('jws-formError');
                    $form.find('label[for=' + element.id + ']').removeClass('jws-formError');
                    $form.find('label[for=' + element.id + ']').addClass(validClass);
                }
            } else {
                $radioElements = $form.find('[name=' + $element.attr('name') + ']');
                if (element.id) {
                    $radioElements.each(function () {
                        $form.find('label[for=' + this.id + ']').removeClass('jws-formError');
                    });
                }
            }
        }
    });

    $.validator.addMethod('invalidvalue', function (value, element) {
        return value !== $(element).attr('data-jwsdw-invalidvalue');
    }, $.validator.messages.email);

    $.validator.addMethod('custompassword', function(value) {
        var minLength = value.length >= 8,
            hasSpecialChar = /[^a-zA-Z0-9]/.test(value),
            hasNum = /\d/.test(value),
            hasUpperCase = /[A-Z]/.test(value);

        return minLength && hasSpecialChar && hasNum && hasUpperCase;
    }, $.validator.messages.invalidPassword);

    $.validator.addMethod('customemail', function(value) {
        var mailRegExp = new RegExp(window.jwsdwSettings.validation.mailAddress.pattern);
        return !value || mailRegExp.test(value);
    }, $.validator.messages.email);

    $.validator.addMethod('digitsonly', function(value) {
        return /^\d*$/.test(value);
    }, $.validator.messages.digits);

    $.validator.addMethod('customname', function(value) {
        return /^[^0-9!?_\[\]{}|><%§=*#]*$/.test(value);
    }, $.validator.messages.invalid);

    $.validator.addMethod('custompostcode', function(value, element) {
        var regexZip,
            $countryCode = $(element).closest('form').find('.jwsdw-input-country'),
            currentCountryCode = window.jwsdwSettings.countryCode;
        if ($countryCode.length > 0) {
            currentCountryCode = $countryCode.val();
        }
        regexZip = new RegExp(window.jwsdwSettings.validation.zipCodeRegex[currentCountryCode]);

        return $(element).prop('required') || value ? regexZip.test(value) : true;
    }, $.validator.messages.invalidZip);

    $.validator.addMethod('isAdult', function (value) {
        return isAdult(value, 18);
    }, $.validator.messages.legalDate);

    $.validator.addMethod('checkDate', function (value, element) {
        return this.optional(element) || checkDate(value);
    }, $.validator.messages.date);

    $.validator.addMethod('pattern', function(value, element) {
        var pattern = new RegExp($(element).attr('pattern'));

        return !value || pattern.test(value);
    }, $.validator.messages.invalid);

    $.validator.addMethod('invalidchars', function(value) {
        var pattern = new RegExp(window.jwsdwSettings.validation.invalidChars);

        return !pattern.test(value);
    }, $.validator.messages.invalid);

    $.validator.addMethod('oneRequired', function (value, element, params) {
        return params.some(function(id) {
            return $('#' + id).val().length > 0;
        });
    }, $.validator.messages.invalid);

    // couponCode validation only for DE / AT
    if (window.jwsdwSettings && /DE|AT/.test(window.jwsdwSettings.countryCode)) {
        $.validator.addMethod('couponCode', function(value) {
            return !/^\d{19}$/.test(value);
        }, $.validator.messages.couponCodeMistakenForGiftCard);

        $.validator.addClassRules('jwsdw-input-couponCode', {
            'couponCode': true
        });
    }

    // Class Rules
    $.validator.addClassRules('jwsdw-input-password', {
        'custompassword': true
    });

    $.validator.addClassRules('jwsdw-input-email', {
        'customemail': true
    });

    $.validator.addClassRules('jwsdw-input-digits', {
        'digitsonly': true
    });


    $.validator.addClassRules('jwsdw-input-name', {
        'customname': true
    });

    $.validator.addClassRules('jwsdw-input-postcode', {
        'custompostcode': true
    });

    $.validator.addClassRules('jwsdw-input-withPattern', {
        'pattern': true
    });

    $.validator.addClassRules('jws-input', {
        'invalidchars': true
    });

    $(document).on('keydown change', '.jws-input', function(e) {
        var $input = $(this);

        if (e.type === 'change') {
            $input.val($input.val().trim());
        }

        setTimeout(function() {
            var required = $input.attr('aria-required');

            if (required && $input.val().length === 0 &&
                !/dateOfBirth(Day|Month)/.test($input.attr('id'))) {
                $input.valid();
            }
        }, 100);
    });


    $(document).on('change', '.jwsdw-input-country', function() {
        $(this).closest('form').find('.jwsdw-input-postcode').valid();
    });

    // initialize form validation
    // http://jqueryvalidation.org/validate/
    $formElements.each(function() {
        $(this).validate();
    });

    /**
     * @description Method to validate date fields
     * @param {String} value date field value
     * @param {Number} min minimum age
     * @returns {Boolean} true if valid
     */
    function isAdult(value, min) { // eslint-disable-line
        var today = new Date(),
            birthDateArr = value.split('/'),
            day = parseInt(birthDateArr[0]),
            month = parseInt(birthDateArr[1]),
            year = parseInt(birthDateArr[2]),
            birthDate;
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            birthDate = new Date(year, month - 1, day);
            today.setFullYear(today.getFullYear() - min);
            return birthDate <= today;
        }

        return true;
    }

    /**
     * @description Method to validate date fields
     * @param {String} value date field value
     * @returns {Boolean} true if valid
     */
    function checkDate(value) { // eslint-disable-line
        var birthDateArr = value.split('/'),
            monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            day = parseInt(birthDateArr[0]),
            month = parseInt(birthDateArr[1]),
            year = parseInt(birthDateArr[2]),
            date = new Date(year, month - 1, day),
            now = new Date();

        // Adjust for leap years
        if (!isNaN(year) &&
            (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))) {
            monthLength[1] = 29;
        }

        // general check if day is valid
        if (!isNaN(day) &&
            (day < 1 || day > 31)) {
            return false;
        }

        // general check if month is valid
        if (!isNaN(month) && (month > 12 || month < 1)) {
            return false;
        }

        // if day & month is set, check if day can fall within month
        if (!isNaN(day) && !isNaN(month) && (day > monthLength[month - 1])) {
            return false;
        }

        // check if year is within reasonable birthday range
        if (!isNaN(year) && year < 1900) {
            return false;
        }

        // check if day is set when month is set
        if ((isNaN(day) && !isNaN(month))) {
            return false;
        }

        // check if day and month are set, when year is set
        if ((isNaN(day) || isNaN(month)) && !isNaN(year)) {
            return false;
        }

        // check if all fields are set
        if ((isNaN(day) || isNaN(month)) || isNaN(year)) {
            return false;
        }

        // if all fields are set, check if date lies in past
        if (!isNaN(day) && !isNaN(month) && !isNaN(year) && (isNaN(date.getTime()) || date.getTime() > now.getTime())) {
            return false;
        }

        return true;
    }
}(jQuery));
